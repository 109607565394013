import { Box, Divider, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Price() {
  return (
    <Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography
          sx={{ fontSize: "30px", fontWeight: "bold", color: "#1664C0" }}
        >
          Состав услуги
        </Typography>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
          Услуга осуществляется в два этапа:
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                padding: "10px 20px",
                width: "230px",
                height: "200px",
                justifyContent: "center",
                border: "1px solid #E9E9E9",
                boxShadow: "5px 5px 5px #E9E9E9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Первый этап</Typography>
              <Box>
                {costsFirst.map((cost) => (
                  <Typography key={cost} sx={{ fontSize: "18px" }}>
                    {cost}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                padding: "10px 20px",
                width: "230px",
                height: "200px",
                justifyContent: "center",
                border: "1px solid #E9E9E9",
                boxShadow: "5px 5px 5px #E9E9E9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Второй этап</Typography>
              <Box>
                {costsSecond.map((cost) => (
                  <Typography key={cost} sx={{ fontSize: "18px" }}>
                    {cost}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              border: "1px solid #E9E9E9",
              width: "900px",
              padding: "20px 20px",
              bgcolor: "#E9E9E9",
              fontStyle: "italic",
            }}
          >
            <Box>
              <Box>
                <InfoOutlinedIcon
                  sx={{ fontSize: "50px", color: "#1664C0", padding: "5px 0" }}
                />
              </Box>
              <Typography>
                Дополнительно оплачиваются переводы документов на испанский
                язык, присяжные переводы, услуги нотариуса, официальные сборы и
                пошлины, медицинские страховки.
              </Typography>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Typography>
                В стоимость услуги не входят шаги, связанные с реализацией
                бизнес-плана, которые заявитель должен осуществить после
                получения резиденции по этой программе.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        <Divider variant="middle" sx={{ maxWidth: "400px", width: "100%" }} />
      </Box>
    </Box>
  );
}

const costsFirst = [
  "Экспертиза",
  "Доработка",
  "перевод на испанский язык бизнес-плана",
  "подача в  Министерство экономики",
];

const costsSecond = ["Подготовка", "Экспертиза и подача документов в UGE "];
