import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { steps } from "./data/data";

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 900, width: "100%" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <div
                style={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {step.label}
              </div>
            </StepLabel>
            <StepContent
              sx={{
                textAlign: "left",
                maxWidth: "900px",
                width: "90%",
                paddingLeft: "5px",
              }}
            >
              <Typography sx={{ fontSize: "18px" }}>
                {step.description}
              </Typography>

              <Typography>{step.requirementsTitle}</Typography>
              <ul style={{ paddingLeft: "25px" }}>
                {step.requirements.map((el) => (
                  <li key={el}>
                    <Typography sx={{ fontSize: "18px" }}>{el}</Typography>
                  </li>
                ))}
              </ul>

              <Typography sx={{ fontSize: "18px" }}>{step.time}</Typography>

              <Typography sx={{ fontSize: "18px" }}>
                Результат: {step.result}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Закончить" : "Дальше"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Назад
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Сбросить
          </Button>
        </Paper>
      )}
    </Box>
  );
}
