export const steps = [
  {
    label:
      "Подача бизнес плана в Министерство экономики (Ministerio de asuntos económicos)",
    description: `Ваш бизнес план направляется в Министерство экономики (Ministerio de asuntos económicos) для получения положительного заключения по бизнес-плану`,
    time: "Срок ответа (предоставления заключения по бизнес-плану) обычно составляет 20 дней",
    requirementsTitle: "Требования к бизнес-плану",
    requirements: [
      "Будущий бизнес должен иметь инновационный характер. Предлагаемый продукт или услуга должны иметь в основе прорывные технологии и идеи и отличаться от того, что уже представлено на рынке",
      "Компания, которую вы намереваетесь создать, должна представлять особый экономический интерес для Испании в целом и региона, где планируется развивать проект",
      "Бизнес должен создавать инвестиционные возможности (быть инвестиционно-привлекательным",
    ],
    result: "Положительное заключение по бизнес-плану",
  },
  {
    label:
      "Подача заявки в UGE (la Unidad de Grandes Empresas y Sectores Estratégicos) ",
    description: "Информация по комплекту документов для заявки",
    time: "20 дней после подачи заявки. Если ответ не предоставлен – решение считается положительным",
    requirementsTitle: "Комплект документов для подачи в UGE",
    requirements: [
      "Положительное заключение по бизнес-плану",
      "Ксерокопии всех страниц загранпаспорта (должен быть действителен на момент подачи заявления)",
      "Частная медицинская страховка с полным покрытием без каких-либо недостатков или доплат, заключенная с испанской компанией",
      "Справка об отсутствии судимости",
      "Подтверждение наличия достаточных финансовых средств на первые два года проживания в Испании заявителя и членов его семьи (Для получения ВНЖ заявитель должен продемонстрировать у него достаточных средств, привязанных к показателю IPREM (прожиточный минимум в месяц) из расчета 100% IPREM в месяц на основного заявителя и 50% IPREM на каждого члена семьи. Учитывая, что IPREM в 2022 году составляет 600 евро, основной заявитель должен показать наличие средств в размере 6 948 евро на один год",
      "Оплата соответствующей пошлины",
      "Заполненную форму заявки",
    ],
    result: "Одобренная заявка",
  },
  {
    label: "Получение ВНЖ",
    description: `В случае положительного решения заявитель и его семья получают вид на жительство на два года (вместо одного года по стандартным процедурам через получение визы D)`,
    time: "",
    requirementsTitle: "",
    requirements: [
      "Необходимо сделать регистрацию по адресу",
      "Сдать отпечатки пальцев",
    ],
    result: "Получение карточки резидента (ВНЖ)",
  },
];
