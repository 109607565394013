import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `'Alegreya SC', serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={THEME}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);
