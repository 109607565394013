import { Box, Typography, Divider } from "@mui/material";

export default function Contacts() {
  return (
    <Box sx={{ marginTop: "30px" }}>
      <Typography
        sx={{ fontSize: "30px", fontWeight: "bold", color: "#1664C0" }}
      >
        Контакты
      </Typography>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <Box
          sx={{
            border: "1px solid #E9E9E9",
            boxShadow: "5px 5px 5px #E9E9E9",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Box
            sx={{
              padding: "20px 20px",
              justifyContent: "space-around",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>Телефон в Испании</Typography>
              <Typography>
                <a href="tel:+34 628 23 12 40">+34 628 23 12 40</a>
              </Typography>
            </Box>

            <Box sx={{ padding: "10px 0" }}>
              <Typography>WhatsApp</Typography>

              <Typography>
                <a href="https://api.whatsapp.com/send?phone=79250597575">
                  +7 925 059 7575
                </a>
              </Typography>
              <Typography>
                <a href="https://api.whatsapp.com/send?phone=34692714700">
                  +34 692 714 700
                </a>
              </Typography>
            </Box>

            <Box>
              <Typography>Телефон в России</Typography>
              <Typography>
                <a href="tel:+7 903 799 5496">+7 903 799 5496</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ margin: "20px 0", display: "flex", justifyContent: "center" }}>
        <Divider variant="middle" sx={{ maxWidth: "400px", width: "100%" }} />
      </Box>
    </Box>
  );
}
