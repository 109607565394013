import { Box, Divider, Button, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import GavelIcon from "@mui/icons-material/Gavel";
import LightbulbCircleIcon from "@mui/icons-material/LightbulbCircle";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";

export default function About() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <Box sx={{ backgroundColor: "#1664C0" }}>
          <Box
            sx={{
              fontSize: "36px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            СТАРТАП ВИЗА В ИСПАНИИ
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              marginTop: "20px",
              maxWidth: "300px",
              width: "100%",
              fontSize: "20px",
            }}
          >
            для тех, кто хочет начать свой стартап проект в Испании
          </Typography>
        </Box>

        <Box
          sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
        >
          <Divider variant="middle" sx={{ maxWidth: "400px", width: "100%" }} />
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <Typography
            sx={{ fontSize: "30px", fontWeight: "bold", color: "#1664C0" }}
          >
            Виза предпринимателя
          </Typography>

          <Box sx={{ marginTop: "20px" }}>
            <img
              src="./images/main1.jpg"
              alt="main_pic"
              style={{
                width: "300px",
                borderRadius: "50%",
                border: "2px solid #1664C0",
              }}
            />
          </Box>

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Divider variant="middle" sx={{ width: "100px" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: "30px",
              maxWidth: "1000px",
              width: "100%",
              fontSize: "20px",
              gap: "25px",
            }}
          >
            <Box sx={{ width: "300px", textAlign: "center" }}>
              <Box>
                <LightbulbCircleIcon
                  sx={{ fontSize: "50px", color: "#1664C0" }}
                />
              </Box>
              <Typography sx={{ marginTop: "20px", fontSize: "18px" }}>
                Предпринимательская виза- это вид на жительство, предоставляемый
                иностранцам, из стран не входящим в ЕС (за пределами
                Европейского Союза), которые хотят развивать предпринимательскую
                деятельность в Испании
              </Typography>
            </Box>
            <Box sx={{ width: "300px", textAlign: "center" }}>
              <Box>
                <GavelIcon sx={{ fontSize: "50px", color: "#1664C0" }} />
              </Box>
              <Typography sx={{ marginTop: "20px", fontSize: "18px" }}>
                На основании закона 14/2013 года для интернационализации
                предпринимателей и иностранных инвестиций, в настоящее время
                можно получить резиденцию в Испании при условии создания
                высокотехнологичной компании
              </Typography>
            </Box>
            <Box sx={{ width: "300px", fontSize: "20px" }}>
              <Box>
                <PeopleIcon sx={{ fontSize: "50px", color: "#1664C0" }} />
              </Box>
              <Typography sx={{ marginTop: "20px", fontSize: "18px" }}>
                Если у проекта несколько основателей, которые будут участвовать
                в создании компании в Испании – то каждый из них может подать
                заявление на получение предпринимательской визы
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <Box sx={{ maxWidth: "700px", width: "100%", fontSize: "20px" }}>
              <Box>
                <FamilyRestroomIcon
                  sx={{ fontSize: "50px", color: "#1664C0" }}
                />
              </Box>
              <Typography sx={{ padding: "0 3px", fontSize: "18px" }}>
                Одним из основных преимуществ является возможность подачи
                совместного заявления с вашим супругом или гражданским
                партнером, несовершеннолетними детьми или иждивенцами
                (экономически зависимыми родителями), которым также будет
                разрешено работать в Испании на законных основаниях
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <Button variant="outlined">
            <a
              href="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2022-21739"
              target="_blank"
              rel="noreferrer"
            >
              Закон
            </a>
          </Button>
        </Box>
        <Box
          sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
        >
          <Divider variant="middle" sx={{ maxWidth: "400px", width: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
}
