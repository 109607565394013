import { Box, Typography, Divider } from "@mui/material";
import VerticalLinearStepper from "../Stepper/Stepper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Process() {
  return (
    <Box sx={{ marginTop: "20px", fontSize: "20px" }}>
      <Box sx={{ fontSize: "30px", fontWeight: "bold", color: "#1664C0" }}>
        Основные этапы оформления визы предпринимателя
      </Box>

      <Box sx={{ marginTop: "30px " }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
          Существует два способы подачи заявки:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "290px",
              padding: "10px 10px",
              border: "1px solid #E9E9E9",
              boxShadow: "5px 5px 5px #E9E9E9",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              sx={{ maxWidth: "300px", width: "100%", fontSize: "18px" }}
            >
              Через Консульство Королевства Испании в РФ (получение визы D)
            </Typography>
          </Box>
          <Box
            sx={{
              width: "290px",
              padding: "10px 10px",
              border: "1px solid #E9E9E9",
              boxShadow: "5px 5px 5px #E9E9E9",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              sx={{ maxWidth: "300px", width: "100%", fontSize: "18px" }}
            >
              Непосредственно в Испании, легально находясь в стране (например,
              по туристической визе)
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
        >
          <Box
            sx={{
              border: "1px solid #E9E9E9",
              maxWidth: "900px",
              width: "100%",
              padding: "20px 20px",
              bgcolor: "#E9E9E9",
              fontStyle: "italic",
            }}
          >
            <Box>
              <InfoOutlinedIcon
                sx={{ fontSize: "50px", color: "#1664C0", padding: "5px 0" }}
              />
            </Box>
            <Typography sx={{ maxWidth: "900px", width: "100%" }}>
              Мы рекомендуем использовать второй способ, учитывая удлинившиеся
              сроки записи подачи заявления на визу D и то, что при подаче
              заявки в Испании первоначальный вид на жительство выдается сразу
              на два года (а не один как в случае с визой D)
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "3px 3px",
          }}
        >
          <VerticalLinearStepper />
        </Box>
      </Box>

      <Box
        sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
      >
        <Divider variant="middle" sx={{ maxWidth: "400px", width: "100%" }} />
      </Box>
    </Box>
  );
}
