import "./App.css";
import About from "./components/About/About";
import Process from "./components/Process/Process";
import Price from "./components/Price/Price";
import Contacts from "./components/Contacts/Contacts";

function App() {
  return (
    <div className="App">
      <About />
      <Process />
      <Price />
      <Contacts />
    </div>
  );
}

export default App;
